import React, { useState, useEffect, useRef } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import DataFilteration from "./DataFilteration";
import createApis from '../api';

const CompleteTableDetails = () => {
  const [data, setData] = useState([]);
  const [hits, setHits] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const technologiesApi = createApis('technologies');

  const fetchData = async (keyword) => {
    try {
      const requestBody = {
        keyword: keyword,
        all: true,
        table_identifier: "complete_record",
        column: "",
      };

      const jsonData = await technologiesApi.fetchByKeyword(requestBody);

      const specialValuesCount = countSpecialValues(jsonData) || 0;
      console.log(
        "Count of None, none, null, or empty string:",
        specialValuesCount
      );

      setHits(jsonData[0].hits - specialValuesCount || 0);
      setData(jsonData[0].results || []);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error);
      setLoading(false);
    }
  };

  // Store hits in localStorage when it changes
  useEffect(() => {
    localStorage.removeItem("hits");
    localStorage.setItem("hits", hits === -1 ? 0 : hits);
  }, [hits]);

  useEffect(() => {
    fetchData(searchTerm !== "" ? searchTerm : "");
  }, [searchTerm]);

  function countSpecialValues(data) {
    let count = 0;

    data.forEach((item) => {
      item.results.forEach((result) => {
        const nrValue = result._source.Nr;
        // Check for "None", "none", "null", or ""
        if (
          nrValue === "None" ||
          nrValue === "none" ||
          nrValue === null ||
          nrValue === "" ||
          nrValue === undefined ||
          nrValue === null
        ) {
          count++;
        }
      });
    });

    return count;
  }

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const tableRef = useRef(null); // Reference to the table element

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      <div className="containerx">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Technologiesuche, bitte Suchwort eingeben"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
          <div className="hit-center">
            <strong>Treffer: {hits === -1 ? 0 : hits}</strong>
          </div>
        </form>
      </div>
      <div>
        {hits === 0 || hits === -1 ? (
          <p>No data available</p>
        ) : (
          <DataFilteration dataProp={data} />
        )}
      </div>
    </div>
  );
};

export default CompleteTableDetails;
