/**
 * API functions for Elasticsearch database operations
 */
export const createElasticDbApis = (apiService) => ({

    /**
     * Import database from file (JSON, CSV, XLSX)
     * @param {FormData} formData - Form data containing the file and file type
     * @returns {Promise} Import result
     */
    importDatabaseFile: async (formData) => {
      return apiService.apiRequest("/import-file", "POST", formData, {
        contentType: null, // Let browser set content type with boundary for FormData
      });
    },

    /**
     * Export database to CSV or XLSX
     * @param {Object} options - Export options
     * @param {string} options.file_format - Format to export ('csv' or 'xlsx')
     * @param {string} options.return_type - How to return data ('data' or 'file')
     * @returns {Promise} Export result with data or file download info
     */
    exportDatabase: async (options = {}) => {
      const { 
        file_format = 'csv',
        return_type = 'data'
      } = options;

      // For file downloads directly from server
      if (return_type === 'file') {
        // Create a URL for direct download
        const url = apiService.getFullUrl(`/export?file_format=${file_format}`);
        
        // Create a hidden iframe for the download to avoid navigating away
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        document.body.appendChild(iframe);
        
        // Set iframe source to the download URL
        iframe.src = url;
        
        // Clean up iframe after a delay
        setTimeout(() => {
          if (document.body.contains(iframe)) {
            document.body.removeChild(iframe);
          }
        }, 5000);
        
        // Return a resolved promise since the download is handled by the browser
        return Promise.resolve({ success: true, message: 'Download initiated' });
      }
      
      // For data return type, make a regular API request
      return apiService.apiRequest("/export", "POST", {
        file_format,
        return_type
      });
    },
  });