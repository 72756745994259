import React, { useRef, useState } from "react";
import { 
  Box, 
  Button, 
  Typography, 
  Card, 
  CardContent, 
  Tooltip, 
  Snackbar, 
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  LinearProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import InfoIcon from "@mui/icons-material/Info";
import { useTranslation } from "react-i18next";
import * as XLSX from 'xlsx';
import createApis from "../../../api";

const ElasticDBControl = () => {
  const { t } = useTranslation();
  const fileInputRef = useRef(null);
  const [notification, setNotification] = useState({ open: false, message: "", severity: "info" });
  const [importFile, setImportFile] = useState(null);
  const [importDialogOpen, setImportDialogOpen] = useState(false);
  const [exportDialogOpen, setExportDialogOpen] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [progressPercent, setProgressPercent] = useState(0);
  const [exportFormat, setExportFormat] = useState("csv");

  // Use Api services for elastic database
  const elasticDbApi = createApis('elastic_db_control');

  // Close notification
  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  // Handle file selection
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    
    setImportFile(file);
    setImportDialogOpen(true);
  };

  // Import database
  const handleImportDB = () => {
    if (!importFile) return;
    
    setImportDialogOpen(false);
    setInProgress(true);
    setProgressPercent(0);
    setNotification({ open: true, message: t("importStarted"), severity: "info" });
    
    const fileExtension = importFile.name.split('.').pop().toLowerCase();
    
    // Create form data to send the file
    const formData = new FormData();
    formData.append('file', importFile);
    formData.append('fileType', fileExtension);
    
    // Simulated progress
    const progressInterval = setInterval(() => {
      setProgressPercent(prev => {
        if (prev >= 90) {
          clearInterval(progressInterval);
          return 90;
        }
        return prev + 10;
      });
    }, 500);
    
    // Call API to import the database
    elasticDbApi.importDatabaseFile(formData)
      .then((response) => {
        clearInterval(progressInterval);
        setProgressPercent(100);
        console.log('Import response:', response.result);
        
        // Check for errors in the response
        if (response.result && response.result.error && response.result.success === false) {
          // Handle as a failure if there are errors in the response
          const errorMessage = response.result.error;

          console.error("Import error:", errorMessage);
          
          setNotification({ 
            open: true, 
            message: t("importError") + ': ' + errorMessage, 
            severity: "error" 
          });
        } else {
          // Only show success if there are no errors
          setNotification({ open: true, message: t("importSuccess"), severity: "success" });
        }
        
        // Reset after a short delay
        setTimeout(() => {
          setInProgress(false);
          setProgressPercent(0);
          setImportFile(null);
          
          // Reset file input
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
        }, 1000);
      })
      .catch((error) => {
        clearInterval(progressInterval);
        console.error("Import failed:", error);
        setNotification({ 
          open: true, 
          message: error.message || t("importError"), 
          severity: "error" 
        });
        setInProgress(false);
        setProgressPercent(0);
        setImportFile(null);
        
        // Reset file input
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      });
  };
  
  // Cancel import
  const handleCancelImport = () => {
    setImportDialogOpen(false);
    setImportFile(null);
    
    // Reset file input
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  // Open export dialog
  const handleOpenExportDialog = () => {
    setExportDialogOpen(true);
  };

  // Close export dialog
  const handleCloseExportDialog = () => {
    setExportDialogOpen(false);
  };

  // Handle export request
  const handleExportDB = () => {
    setExportDialogOpen(false);
    setInProgress(true);
    setProgressPercent(0);
    setNotification({ open: true, message: t("exportStarted"), severity: "info" });
    
    // Simulated progress
    const progressInterval = setInterval(() => {
      setProgressPercent(prev => {
        if (prev >= 90) {
          clearInterval(progressInterval);
          return 90;
        }
        return prev + 10;
      });
    }, 500);
    
    // Call API to export data with return_type='data'
    elasticDbApi.exportDatabase({
      file_format: exportFormat,
      return_type: 'data'
    })
      .then((response) => {
        clearInterval(progressInterval);
        setProgressPercent(100);
        
        if (response.success) {
          // Download the file using client-side processing
          downloadData({
            data: response.data,
            fields: response.fields,
            filename: response.filename || `export.${exportFormat}`,
            format: exportFormat
          });
          
          setNotification({ 
            open: true, 
            message: t("exportSuccess"), 
            severity: "success" 
          });
        } else {
          throw new Error(response.error || t("exportError"));
        }
        
        // Reset after a short delay
        setTimeout(() => {
          setInProgress(false);
          setProgressPercent(0);
        }, 1000);
      })
      .catch((error) => {
        clearInterval(progressInterval);
        console.error("Export failed:", error);
        setNotification({ 
          open: true, 
          message: error.message || t("exportError"), 
          severity: "error" 
        });
        setInProgress(false);
        setProgressPercent(0);
      });
  };

  // Function to download data based on format
  const downloadData = ({ data, fields, filename, format }) => {
    if (!data || !data.length) {
      console.error("No data to download");
      return;
    }

    // Use provided fields or extract from first object
    const headers = fields || Object.keys(data[0]);

    if (format.toLowerCase() === 'xlsx') {
      try {
        // Create a new workbook
        const wb = XLSX.utils.book_new();
        
        // Convert data to worksheet
        const ws = XLSX.utils.json_to_sheet(data, { header: headers });
        
        // Add worksheet to workbook
        XLSX.utils.book_append_sheet(wb, ws, "Data");
        
        // Generate XLSX file and trigger download
        XLSX.writeFile(wb, filename);
        return;
      } catch (error) {
        console.error('Error generating XLSX:', error);
        // Fall back to CSV
        format = 'csv';
        filename = filename.replace(/\.xlsx$/i, '.csv');
        setNotification({
          open: true,
          message: t("xlsxFallbackWarning"),
          severity: "warning"
        });
      }
    }
    
    if (format.toLowerCase() === 'csv') {
      // Convert to CSV
      const csvContent = convertToCSV(data, headers);
      
      // Create blob and trigger download
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      
      // Create a temporary URL to the blob
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      // Clean up the URL
      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 100);
    }
  };

  // Convert data to CSV format
  const convertToCSV = (data, headers) => {
    if (!data || !data.length) return '';
    
    // Create header row
    let csvContent = headers.join(',') + '\n';
    
    // Add data rows
    data.forEach(item => {
      const row = headers.map(header => {
        const value = item[header];
        // Handle null/undefined values
        if (value === null || value === undefined) return '';
        
        const stringValue = String(value);
        // Escape quotes and wrap in quotes if the value contains commas, quotes, or newlines
        if (stringValue.includes(',') || stringValue.includes('"') || stringValue.includes('\n')) {
          return `"${stringValue.replace(/"/g, '""')}"`;
        }
        return stringValue;
      });
      
      csvContent += row.join(',') + '\n';
    });
    
    return csvContent;
  };

  return (
    <Card variant="outlined" sx={{ width: "100%" }}>
      
      <CardContent>
         {/* Export hint message */}
      <Box sx={{ mb: 3, p: 2, bgcolor: 'info.lighter', borderRadius: 1, display: 'flex', alignItems: 'center' }}>
        <InfoIcon color="info" sx={{ mr: 1 }} />
        <Typography variant="body2">
          {t("importHintMessage") || "Hint: It's recommended to export your current database before importing to avoid data loss."}
        </Typography>
      </Box>
        {inProgress && (
          <Box sx={{ width: '100%', mb: 3 }}>
            <LinearProgress variant="determinate" value={progressPercent} />
            <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 1 }}>
              {progressPercent}%
            </Typography>
          </Box>
        )}
        
        <Box mt={3} display="flex" justifyContent="center" gap={2}>
          <input
            type="file"
            accept=".csv,.xlsx"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileSelect}
          />
          <Tooltip title={t("importDBTooltip")}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<CloudUploadIcon />}
              onClick={() => fileInputRef.current.click()}
              disabled={inProgress}
            >
              {t("importDB")} (.csv, .xlsx)
            </Button>
          </Tooltip>
          <Tooltip title={t("exportDBTooltip")}>
            <Button
              variant="contained"
              color="info"
              size="large"
              startIcon={<CloudDownloadIcon />}
              onClick={handleOpenExportDialog}
              disabled={inProgress}
            >
              {t("exportDB")}
            </Button>
          </Tooltip>
        </Box>
        
      </CardContent>

      {/* Warning Dialog before import */}
      <Dialog
        open={importDialogOpen}
        onClose={handleCancelImport}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("importWarning")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("importWarningMessage")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelImport} color="primary">
            {t("cancel")}
          </Button>
          <Button onClick={handleImportDB} color="error" autoFocus>
            {t("proceed")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Export dialog */}
      <Dialog
        open={exportDialogOpen}
        onClose={handleCloseExportDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{t("exportDB")}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="format-select-label">{t("exportFormat")}</InputLabel>
                <Select
                  labelId="format-select-label"
                  id="format-select"
                  value={exportFormat}
                  label={t("exportFormat")}
                  onChange={(e) => setExportFormat(e.target.value)}
                >
                  <MenuItem value="csv">CSV</MenuItem>
                  <MenuItem value="xlsx">Excel (XLSX)</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseExportDialog} color="primary">
            {t("cancel")}
          </Button>
          <Button 
            onClick={handleExportDB} 
            color="primary" 
            variant="contained"
          >
            {t("export")}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar 
        open={notification.open} 
        autoHideDuration={6000} 
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseNotification} severity={notification.severity}>
          {notification.message}
        </Alert>
      </Snackbar>
    </Card>
  );
};

export default ElasticDBControl;