import React, { useState, useEffect } from "react";
import { Button, TextField, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormSubmitHandlers } from "../../handlers";
import { useAuthForm } from "../../hooks/useAuth";
import { useValidateForm } from "../../hooks/useValidateForm";

const AuthForm = ({ isRegister, onSuccess }) => {
  const { t } = useTranslation();
  const { formData, handleChange, resetForm } = useAuthForm();
  const validateForm = useValidateForm();
  const [errors, setErrors] = useState({});

  const { handleSubmit } = useFormSubmitHandlers({
    isRegister,
    formData,
    onSuccess: () => {
      if (onSuccess) onSuccess(); // Call onSuccess after successful submission
      resetForm(); // Reset form data after success
    },
  });

  useEffect(() => {
    // Reset the form whenever the isRegister value changes
    resetForm();
    setErrors({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRegister]);

  const onSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm(
      formData,
      "auth",
      isRegister ? "register" : "login"
    );
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      handleSubmit(e);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={onSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        maxWidth: 400,
        mx: "auto",
      }}
    >
      <TextField
        label={t("username")}
        name="username"
        variant="outlined"
        value={formData.username}
        onChange={handleChange}
        required
        fullWidth
      />
      {isRegister && (
        <TextField
          label={t("email")}
          name="email"
          variant="outlined"
          value={formData.email}
          onChange={handleChange}
          error={!!errors.email}
          helperText={errors.email}
          required
          fullWidth
        />
      )}
      <TextField
        label={t("password")}
        name="password"
        type="password"
        variant="outlined"
        value={formData.password}
        onChange={handleChange}
        error={!!errors.password}
        helperText={errors.password}
        required
        fullWidth
      />
      {isRegister && (
        <TextField
        label={t("confirm_password")}
        name="confirmPassword"
        type="password"
        variant="outlined"
        value={formData.confirmPassword}
        onChange={handleChange}
        error={!!errors.confirmPassword}
        helperText={errors.confirmPassword}
        required
        fullWidth
        />
      )}
      <Button type="submit" variant="contained" color="primary" fullWidth>
        {isRegister ? t("register") : t("login")}
      </Button>
    </Box>
  );
};

export default AuthForm;
