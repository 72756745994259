import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { Navbar } from 'react-bootstrap';
import createApis from '../api';


const DeleteDataForm = () => {
  const [id, setId] = useState('');
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);

  const technologiesApi = createApis('technologies');

  // Function to handle input change
  const handleInputChange = (event) => {
    setId(event.target.value);
  };

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission

    try {
      const jsonData = await technologiesApi.deleteById(id);

      console.log('✌️jsonData --->', jsonData);
      alert(jsonData.result);
      if (jsonData.result === "deleted") {
        setMessage(`Successfully deleted item with ID: ${id}`);
        setError(null);
      } else {
        setMessage(`Failed to delete item with ID: ${id}`);
        setError(null);
      }
      
    } catch (error) {
      setError(error.message);
      setMessage(null);
    }
  };

//   // Effect to clear the message after 10 seconds
//   useEffect(() => {
//     if (message) {
//       const timer = setTimeout(() => {
//         setMessage(null);
//       }, 3000); // 10000 milliseconds = 10 seconds

//       // Cleanup function to clear the timer if the component unmounts or message changes
//       return () => clearTimeout(timer);
//     }
//   }, [message]);

  return (
    <div className="container mt-5">
      <Navbar bg="light" expand="md" fixed="top" className="container-nav navbar-custom d-flex justify-content-center align-items-center">
        <Navbar.Brand href="/" className="color-maroon">
          KI-Kompass Inklusiv
        </Navbar.Brand>
      </Navbar>

      <br />
      <br/>
      <form onSubmit={handleSubmit} className="form-inline">
        <div className="form-group mb-2">
          <label htmlFor="id" className="sr-only">ID:</label>
          <input
            type="text"
            id="id"
            value={id}
            onChange={handleInputChange}
            className="form-control mr-2"
            placeholder="Please Enter the ID to Delete"
          />
        </div>
        <button type="submit" className="btn btn-danger mb-2" disabled={!id}>
          Delete
        </button>
      </form>

      {/* Display success message or error message */}
      {message && <p className="text-success mt-2">{message}</p>}
      {error && <p className="text-danger mt-2">{error}</p>}
    </div>
  );
};

export default DeleteDataForm;