import { useEffect } from "react";
import { useStateContext } from "../context/stateContext";
import createApis from "../api";
import { useCrudHandlers } from "../handlers";
import { useAuth } from "./useAuth";

const usePrivilegesCheck = (requiredPrivileges = []) => {
  const { user } = useAuth();
  const { privileges, setPrivileges } = useStateContext(); // Store user privileges in context
  const usersApi = createApis('users');
  const { fetchEntity } = useCrudHandlers(usersApi);

  useEffect(() => {
    const cachedPrivileges = localStorage.getItem("userPrivileges");

    // If user is logged in and privileges are not set in context
    if (user && privileges === null) {
      if (cachedPrivileges) {
        // Use cached privileges if available
        setPrivileges(JSON.parse(cachedPrivileges));
      } else {
        // Fetch privileges from the server if not cached
        const fetchUserDetails = async () => {
          try {
            const userData = await fetchEntity(user.user_id);
            setPrivileges(userData.privileges);
            // Cache the privileges in localStorage
            localStorage.setItem(
              "userPrivileges",
              JSON.stringify(userData.privileges)
            );
          } catch (error) {
            console.error("Error fetching user details:", error);
            setPrivileges([]); // Set to empty array if fetching fails
            localStorage.removeItem("userPrivileges"); // Clear cache on error
          }
        };

        fetchUserDetails();
      }
    }
  }, [user, privileges, fetchEntity, setPrivileges]);

  // If user has "all" privilege, they have access to everything
  // Return null initially if privileges haven't been loaded yet
  const hasPrivileges =
    privileges === null
      ? null
      : privileges.includes("all") ||
        requiredPrivileges.every((priv) => privileges.includes(priv));

  return { user, hasPrivileges };
};

export default usePrivilegesCheck;
