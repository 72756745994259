import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import usePrivilegesCheck from "../hooks/usePrivilegesCheck";

// AdminRoute to protect admin-only routes
const AdminRoute = ({ children }) => {
  const { user, hasPrivileges: isAdmin } = usePrivilegesCheck(["all"]); // Check for "all" privilege for admin access

  // If user is not authenticated, redirect to login
  if (!user) {
    return <Navigate to="/" />;
  }

  // If the user is not an admin, redirect to the dashboard
  if (isAdmin === false) {
    return <Navigate to="/dashboard" />;
  }

  // If the user is an admin, render the children (protected admin content)
  return children;
};

// ProtectedRoute to guard routes for authenticated users only
const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();

  if (!user) return <Navigate to="/" />;

  return children;
};

// LoginRoute to redirect authenticated users away from login page
const LoginRoute = ({ element }) => {
  const { user } = useAuth();
  return user ? <Navigate to="/dashboard" /> : element;
};

export { AdminRoute, ProtectedRoute, LoginRoute };
