import React from "react";
import { Snackbar, Alert } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "../theme";

// Handlers
import { useSnackbarHandlers } from "../handlers";

// State Context
import { useStateContext } from "../context/stateContext";

const MainLayout = ({ children }) => {
  const { handleSnackbarClose } = useSnackbarHandlers();

  // Destructuring snackbar state from custom hook
  const { snackbar } = useStateContext();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default MainLayout;
