import { useSnackbarHandlers } from "./snackbarHandlers";

// A custom hook for handling Statistics API
export const useStatisticsHandlers = (api) => {
  // Destructuring handlers from snackbar handlers hook
  const { handleSnackbar } = useSnackbarHandlers();

  // Fetch statistics data from the server
  const fetchStatistics = async () => {
    try {
      const data = await api.getStatistics();
      return data;
    } catch (error) {
      console.error("Error fetching statistics:", error);
      handleSnackbar("Failed to fetch statistics", "error");
      throw error;
    }
  };

  // Returning the necessary function for statistics handling
  return {
    fetchStatistics,
  };
};
