import React, { useEffect, useState } from "react";
import TechnologyDetailsCard from "./TechnologyDetailsCard";

import createApis from '../api';

const TechnologyDetails = ({ id }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [details, setDetails] = useState(null);

  const technologiesApi = createApis('technologies');


  const fetchById = async (id) => {
    try {

      const jsonData = await technologiesApi.getById(id);

      setDetails(jsonData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data by ID:", error);
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchById(id);
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      <TechnologyDetailsCard result={details.result} id={id} />
    </div>
  );
};

export default TechnologyDetails;
