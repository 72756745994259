import React, { useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, Switch } from '@mui/material';
import { useTranslation } from 'react-i18next';
import UserForm from './UserForm';
import Actions from '../common/Actions';
import createApis from '../../../api';
import DeleteConfirmationDialog from '../common/DeleteConfirmationDialog';
import { useStateContext } from '../../../context/stateContext';
import {
  useCrudHandlers,
  useFormHandlers,
  useDeleteHandlers
} from '../../../handlers';

const Users = () => {

  const { t } = useTranslation();

  const newUser = { username: '', password: '', email: '', role_id: 1, state: true };

  // Destructuring handlers and state from custom hook
  const { entities, selectedRows, setSelectedRows } = useStateContext();

  // Use Api services for users Api handlers
  const usersApi = createApis('users')

  const {
    handleSaveCell,
    fetchAllEntities,
    handleSaveEntity,
  } = useCrudHandlers(usersApi);

  const {
    isBulkDelete,
    deleteDialogOpen,
    setDeleteDialogOpen,
    handleDeleteEntityConfirm,
    handleDeleteEntityInitiate,
    handleDeleteSelectedEntitiesConfirm
  } = useDeleteHandlers(usersApi);

  const {
    newEntity,
    editMode,
    openEntityForm,
    setNewEntity,
    handleOpenEntityForm,
    handleCloseEntityForm,
  } = useFormHandlers();

  // Handle switch change
  const handleStateChange = async (id, checked) => {
    const updatedEntity = entities.find(entity => entity.id === id);
    if (updatedEntity) {
      updatedEntity.state = checked;
      await handleSaveEntity('PUT', updatedEntity);
    }
  };

  // Fetch users data on component mount
  useEffect(() => {
    fetchAllEntities();
    // eslint-disable-next-line
  }, []);

  // Define columns for DataGrid
  const columns = [
    { field: 'username', headerName: t('username'), width: 150, editable: false },
    { field: 'email', headerName: t('email'), width: 300, editable: false },
    { field: 'role_name', headerName: t('userRole'), width: 150, editable: false },
    {
      field: 'state',
      headerName: t('state'),
      width: 150,
      editable: true,
      renderCell: params => (
        <Switch
          checked={params.row.state}
          onChange={(event) => handleStateChange(params.row.id, event.target.checked)}
          color="primary"
        />
      ),
    }, {
      field: 'actions',
      headerName: t('actions'),
      width: 150,
      sortable: false,
      renderCell: params => (
        <Actions
          onEdit={() => handleOpenEntityForm(params.row)}
          onDelete={() => handleDeleteEntityInitiate(params.row.id)}
        />
      ),
    },
  ];



  return (
    <div style={{ height: 400, width: '100%' }}>
      <Box mb={2} display="flex" justifyContent="flex-end">
        <Button variant="contained" color="primary" onClick={() => handleOpenEntityForm(newUser)} style={{ marginRight: '10px' }}>
          {t('addUser')}
        </Button>
        <Button variant="contained" color="secondary" onClick={() => handleDeleteEntityInitiate(null, true)} disabled={selectedRows.length === 0}>
          {t('deleteSelected')}
        </Button>
      </Box>
      <DataGrid
        rows={entities}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
        onCellEditStop={handleSaveCell}
        selectionModel={selectedRows}
        onRowSelectionModelChange={setSelectedRows}
      />
      <UserForm
        open={openEntityForm}
        handleClose={handleCloseEntityForm}
        user={newEntity}
        setUser={setNewEntity}
        handleSave={editMode ? () => handleSaveEntity('PUT', newEntity) : () => handleSaveEntity('POST', newEntity)}
        editMode={editMode}
      />
      <DeleteConfirmationDialog
        open={deleteDialogOpen}
        message={isBulkDelete ? t('delteUsersConfirmation') : t('deleteUserConfirmation')}
        handleClose={() => setDeleteDialogOpen(false)}
        handleConfirm={isBulkDelete ? handleDeleteSelectedEntitiesConfirm : handleDeleteEntityConfirm}
      />
    </div>
  );
};

export default Users;