import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';


const Actions = ({ onEdit, onDelete }) => {

  const { t } = useTranslation();

  return (
    <>
      <Tooltip title={t('editTooltip')} arrow>
        <IconButton color="primary" onClick={onEdit}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('deleteTooltip')} arrow>
      <IconButton color="error" onClick={onDelete}>
        <DeleteIcon />
      </IconButton>
      </Tooltip>
    </>
  )
};

export default Actions;
