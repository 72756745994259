// /context/StateContext.jsx
import React, { createContext, useContext, useState } from 'react';

const StateContext = createContext();

export const StateProvider = ({ children }) => {
  const [entities, setEntities] = useState([]);
  const [entity, setEntity] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' }); // State for snackbar notifications
  const [privileges, setPrivileges] = useState(null);

  return (
    <StateContext.Provider value={{ entities, setEntities, entity, setEntity, selectedRows, setSelectedRows, snackbar, setSnackbar, privileges, setPrivileges }}>
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
