import { useTranslation } from "react-i18next";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const minPasswordLength = 8;

export const useValidateForm = () => {
  const { t } = useTranslation();

  return (formData, formType, context) => {
    const errors = {};

    switch (formType) {
      case "auth":
        if (context === "register") {
          if (formData.email && !emailRegex.test(formData.email.trim())) {
            errors.email = t("emailInvalid");
          }

          if (!formData.password || formData.password.length < minPasswordLength) {
            errors.password = t("passwordShort", { min: minPasswordLength });
          }

          if (formData.password !== formData.confirmPassword) {
            errors.confirmPassword = t("passwordMismatch");
          }
        }
        break;

      default:
        break;
    }

    return errors;
  };
};
