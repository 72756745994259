import React, { useState, useEffect } from "react";
import formFields from "./formFields";
import "./CreateForm.css";
import { Navbar } from 'react-bootstrap';
import createApis from '../api';
import { labelMapping } from "./formFields";

const CreateForm = () => {
  const [formData, setFormData] = useState(formFields);
  const [hits, setHits] = useState(0);
  const [showScrollToTop, setShowScrollToTop] = useState(false); // State for scroll-to-top button visibility


  const technologiesApi = createApis('technologies');


  // Retrieve hits from localStorage on component mount 
  useEffect(() => {
    const storedHits = localStorage.getItem('hits');
    if (storedHits !== null) {
      setHits(Number(storedHits));
    }
  }, []);

  // Show "scroll to top" button when user scrolls down
  useEffect(() => {
    const handleScroll = () => {
      setShowScrollToTop(window.scrollY > 200); // Show button if scrolled down 200px
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const body = { data: formData };
      const response = await technologiesApi.createNewRecord(body);
      alert(response.result);
    } catch (error) {
      console.error("Error updating data", error);
    }
  };

  const suppressEnter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const isSubmitDisabled = !formData["Name der Technologie"] || !formData["Technologiebeschreibung"];

  // here 50 denotes the number of words
  const renderInputField = (key, value) => {
    const safeValue = value ?? "";
    if (safeValue.length > 50) {
      return (
        <textarea
          name={key}
          value={safeValue}
          onChange={handleChange}
          className="form-input"
          rows={4}
        />
      );
    }
    return (
      <input
        type="text"
        name={key}
        value={safeValue}
        onChange={handleChange}
        onKeyDown={suppressEnter}
        className="form-input"
      />
    );
  };

  return (
    <div className="container">
      <Navbar bg="light" expand="md" fixed="top" className="container-nav navbar-custom d-flex justify-content-center align-items-center">
        <Navbar.Brand href="/" className="color-maroon">
          KI-Kompass Inklusiv
        </Navbar.Brand>
      </Navbar>
      <br />
      <br />
      <br />
      <br />
      <div className="hit-center">
        <strong>Treffer: {hits === -1 ? 0 : hits}</strong>
      </div>
      <form onSubmit={handleSubmit}>
        {Object.keys(formData).map((key) => (
          <div key={key} className="form-group">
            <label>{labelMapping[key]}:</label>
            {renderInputField(key, formData[key])}
          </div>
        ))}
        <button type="submit" className="btn btn-primary" disabled={isSubmitDisabled}>
          Create
        </button>
      </form>
      {showScrollToTop && (
        <button
          onClick={scrollToTop}
          className="scroll-to-top"
          aria-label="Scroll to top"
        >
          ↑
        </button>
      )}
    </div>
  );
};

export default CreateForm;
