import React, { useState, useEffect } from "react";
import formFields from "./formFields";
import { Navbar, Button } from 'react-bootstrap';
import { labelMapping } from "./formFields";
import "./CreateForm.css";

import createApis from '../api';

const initialFormFields = { id: "", ...formFields }; // Renamed for clarity

const UpdateForm = ({ fetchedDataById = {} }) => {
  const [formData, setFormData] = useState(initialFormFields);
  const [id, setID] = useState(null);
  const [hits, setHits] = useState(0);
  const [showScrollToTop, setShowScrollToTop] = useState(false); // State to control button visibility

  const technologiesApi = createApis('technologies');

  // Update formData when fetchedDataById changes
  useEffect(() => {
    if (fetchedDataById) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        ...fetchedDataById
      }));
    }
  }, [fetchedDataById]);

  // Retrieve hits from localStorage on component mount
  useEffect(() => {
    const storedHits = localStorage.getItem('hits');
    if (storedHits !== null) {
      setHits(Number(storedHits));
    }
  }, []);

  // Show "scroll to top" button when user scrolls down
  useEffect(() => {
    const handleScroll = () => {
      setShowScrollToTop(window.scrollY > 200); // Show button if scrolled down 200px
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "id") {
      setID(value);
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const filteredData = Object.fromEntries(
      // Only filter out the ID field, but keep empty strings
      Object.entries(formData).filter(([key, value]) => key !== "id")

      // Object.entries(formData).filter(([key, value]) => value !== "" && key !== "id")
    );

    try {
      const response = await technologiesApi.updateById(id, {
        updated_data: filteredData,
      });

      if (response.result.error) {
        alert(response.result.error);
      } else {
        alert(response.result);
      }
    } catch (error) {
      console.error("Error updating data", error);
    }
  };

  const suppressEnter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const isSubmitDisabled = !formData.id;

  // Function to determine if a textarea should be used
  const renderInputField = (key, value) => {
    // Ensure value is not null before checking its length
    const safeValue = value ?? ""; // Default to empty string if value is null

    if (key === "id") {
      return (
        <input
          type="text"
          name={key}
          value={safeValue}
          onChange={handleChange}
          className="form-input"
          placeholder="Enter Id to update the record"
        />
      );
    }
    
    // Check if the value length exceeds the threshold for a textarea
    if (safeValue.length > 50) { // You can adjust this threshold as needed
      return (
        <textarea
          name={key}
          value={safeValue}
          onChange={handleChange}
          className="form-input"
          rows={4} // Adjust number of rows as needed
        />
      );
    }

    return (
      <input
        type="text"
        name={key}
        value={safeValue}
        onChange={handleChange}
        className="form-input"
      />
    );
  };

  return (
    <>
      <Navbar bg="light" expand="md" fixed="top" className="container-nav navbar-custom d-flex justify-content-center align-items-center">
        <Navbar.Brand href="/" className="color-maroon">
          KI-Kompass Inklusiv
        </Navbar.Brand>
      </Navbar>

      <br />
      <div className="hit-center">
        <strong>Treffer: {hits === -1 ? 0 : hits}</strong>
      </div>
      <form onSubmit={handleSubmit}>
        {Object.keys(formData).map((key) => (
          <div key={key} className="form-group">
            <label>{key === "id" ? "Id*" : labelMapping[key]}:</label>
            {renderInputField(key, formData[key])}
          </div>
        ))}
        <Button type="submit" className="btn btn-primary" disabled={isSubmitDisabled}>
          Update
        </Button>
      </form>

      {/* Scroll to Top Button */}
      {showScrollToTop && (
        <button
          onClick={scrollToTop}
          className="scroll-to-top"
          aria-label="Scroll to top"
        >
          ↑
        </button>
      )}
    </>
  );
};

export default UpdateForm;
