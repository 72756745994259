// src/theme.js
import { createTheme } from "@mui/material/styles";
import { deDE } from '@mui/x-data-grid/locales';
import {useMediaQuery, useTheme} from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: "#7367F0",
      light: "#8f85f3",
      dark: "#675cd8",
      contrastText: "#ffffff"
    },
    secondary: {
      main: "#808390",
      light: "#999CA6",
      dark: "#737682",
      contrastText: "#ffffff"
    },
    error: {
      main: "#FF4C51",
      light: "#FF7074",
      dark: "#E64449",
      contrastText: "#ffffff"
    },
    warning: {
      main: "#FF9F43",
      light: "#FFB269",
      dark: "#E68F3C",
      contrastText: "#ffffff"
    },
    info: {
      main: "#00BAD1",
      light: "#33C8DA",
      dark: "#00A7BC",
      contrastText: "#ffffff"
    },
    success: {
      main: "#28C76F",
      light: "#53D28C",
      dark: "#24B364",
      contrastText: "#ffffff"
    },
    background: {
      default: "#f5f5f5",
      paper: "#ffffff",
    },
    text: {
      primary: "#000000",
      secondary: "#e1def5e6",
      light: "#e1def5e6",
      default: "#2f2b3de6"
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
}, deDE);

export const useResponsive = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  
  return { isSmallScreen, isMediumScreen, isLargeScreen };
};

export default theme;
