import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import TechnologyDetails from "./TechnologyDetails"; // Import the TechnologyDetails component
import createApis from '../api';

const TableComponent = () => {
  const [data, setData] = useState([]);
  const [hits, setHits] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTechnologyId, setSelectedTechnologyId] = useState(null); // State to keep track of selected technology id
  const tableRef = useRef(null); // Reference to the table element

  const technologiesApi = createApis('technologies')

  
  function countSpecialValues(data) {
    let count = 0;

    data.forEach(item => {
        item.results.forEach(result => {
            const nrValue = result.Nr;
            // Check for "None", "none", "null", or "" or "null"
            if (nrValue === "None" || nrValue === "none" || nrValue === null || nrValue === "" || nrValue === "null") {
                count++;
            }
        });
    });

    return count;
  }

  const fetchData = async (keyword) => {
    try {
        const requestBody = {
            keyword: keyword,
            table_identifier: "id_tech_desc",
            column: ""
        };

        const jsonData = await technologiesApi.fetchByKeyword(requestBody);

        const specialValuesCount = countSpecialValues(jsonData);
        // console.log("Count of None, none, null, or empty string:", specialValuesCount);

        setHits(jsonData[0]?.hits - specialValuesCount || 0);
        setData(jsonData[0]?.results || []);
        setLoading(false);
    } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
        setLoading(false);
    }
};

  useEffect(() => {
      fetchData(searchTerm !== "" ? searchTerm : "");
  }, [searchTerm]);


  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleTechnologyClick = (e, id) => {
    e.preventDefault(); // Prevent default anchor behavior
    setSelectedTechnologyId(id === selectedTechnologyId ? null : id);
    // Scroll the table into view
    // tableRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    document.documentElement.scrollTop = 0;
    document.documentElement.scrollTop += 10;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="containerx">
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="input-group mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Technologiesuche, bitte Suchwort eingeben"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
        <strong>Treffer: {hits === -1 ? 0 : hits}</strong>
      </form>
      <br />
      <table ref={tableRef} className="table table-bordered">
        {" "}
        {/* Add ref attribute */}
        <thead className="thead-dark">
          <tr>
            <th className="align-left">Technologiename</th>
            <th className="align-left">Kurzbeschreibung</th>
            <th></th> {/* Placeholder for the icon column */}
          </tr>
        </thead>
        <tbody>
          {data && data.length > 0 ? (
            data
              .filter((item) => !(item["technology_name"] === null))
                //   .filter((item) => {
                //     if (!(item["technology_name"] === null)) {
                //         console.log(item["technology_name"]); // Logging the item
                //         console.log(item["technology_name"] === "IndiFon"); // Logging the item
                //         return true; // Keeping the item in the filtered array
                //     } else {
                //         return false; // Excluding the item from the filtered array
                //     }
                // })
              .map((item) => (
                <React.Fragment key={item._id}>
                  <tr>
                    <td className="align-left" style={{ backgroundColor: item.suggestion === true ? "rgba(155, 221, 155, 0.5)" : "" }}>
                      <a
                        href="#"
                        onClick={(e) => handleTechnologyClick(e, item._id)}
                        title="Steckbrief"
                      >
                        {item.technology_name}
                      </a>
                    </td>
                    <td className="align-left">{item.description}</td>
                    <td>
                      {selectedTechnologyId === item._id ? (
                        <FontAwesomeIcon
                          icon={faChevronUp}
                          onClick={(e) => handleTechnologyClick(e, item._id)}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          onClick={(e) => handleTechnologyClick(e, item._id)}
                        />
                      )}
                    </td>
                  </tr>
                  {selectedTechnologyId === item._id && (
                    <tr>
                      <td colSpan="3">
                        <TechnologyDetails id={selectedTechnologyId} />
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))
          ) : (
            <tr>
              <td colSpan="3">No data available</td>
            </tr>
          )}
        </tbody>

      </table>
    </div>
  );
};

export default TableComponent;
