import { useState } from 'react';
import { useStateContext } from '../context/stateContext';
import { useSnackbarHandlers } from './snackbarHandlers';

// A custom hook for handling forms
export const useFormHandlers = () => {
  const [openEntityForm, setOpenEntityForm] = useState(false); // State for controlling entity form visibility
  const [newEntity, setNewEntity] = useState({}); // State for storing new entity data 
  const [editMode, setEditMode] = useState(false); // State for tracking if in edit mode
  

  const { entities, setEntities } = useStateContext();

  // Destructuring handlers from snackbar handlers hook
  const {
    handleSnackbar,
  } = useSnackbarHandlers();

  // Handle opening entity form for adding/editing an entity
  const handleOpenEntityForm = (entity = {}) => {
    setEditMode(!!entity.id);
    setOpenEntityForm(true);
    setNewEntity({ ...entity });
  };

  // Handle closing entity form
  const handleCloseEntityForm = () => setOpenEntityForm(false);

   // Handle saving a cell in the grid
   const handleSaveCell = (params, event) => {
    const newValue =
      event.target.value !== undefined ? event.target.value : params.value;
    const trimmedNewValue = newValue.trim();
    if (trimmedNewValue === "") {
      handleSnackbar("Value cannot be empty!", "error");
      return;
    }
    const updatedEntities = entities.map((entity) =>
      entity.id === params.id
        ? { ...entity, [params.field]: trimmedNewValue }
        : entity
    );
    setEntities(updatedEntities);
  };

  // Returning all the necessary functions and states for entity handling
  return {
    newEntity,
    editMode,
    openEntityForm,
    setNewEntity,
    setEditMode,
    handleSaveCell,
    handleOpenEntityForm,
    handleCloseEntityForm,
  };
};
