import { useStateContext } from '../context/stateContext';

// A custom hook for handling snackbar
export const useSnackbarHandlers = () => {
  const { snackbar, setSnackbar} = useStateContext();

   // Handle displaying snackbar notifications
   const handleSnackbar = (message, severity = 'success') => {
    setSnackbar({ open: true, message, severity });
  };

  // Handle closing snackbar
  const handleSnackbarClose = () => setSnackbar({ ...snackbar, open: false });

  return {
    snackbar,
    handleSnackbar,
    handleSnackbarClose,
  };
};
