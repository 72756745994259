export const createTechnologyApis = (apiService) => ({
    fetchByKeyword: async (requestBody) => {
      return apiService.apiRequest("/getbykeyword", "POST", requestBody);
    },
  
    getById: async (recordId) => {
      return apiService.apiRequest(`/getbyid/${recordId}`);
    },
  
    createNewRecord: async (newRecord) => {
      return apiService.apiRequest("/createNewRecord", "POST", newRecord);
    },
  
    updateById: async (recordId, updatedRecord) => {
      return apiService.apiRequest(`/updatebyid/${recordId}`, "POST", updatedRecord);
    },
  
    deleteById: async (recordId) => {
      return apiService.apiRequest(`/deletebyid/${recordId}`, "DELETE");
    },
  
  });